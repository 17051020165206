<template>
	<div class="mt-5">
		<b>To begin word frequency analysis, select records or drop them here</b>
		<tera-file-select
			:title="'Select records to analyse...'"
			:filters="{library: true}"
			placeholder="Select records to analyse..."
			@change="fileUpload"
			class="my-2"
			style="background: #FFF"
		/>
	</div>
</template>

<script>
import importMixin from "../mixins/importMixin"
import TeraFileSelect from '@iebh/tera-fy/widgets/tera-file-select.vue';

export default {
	name: 'FileUpload',
	mixins: [importMixin],
	components: {
		TeraFileSelect,
	},
	methods: {
		fileUpload(file) {
			this.importFile(file);
		}
	}
}
</script>
